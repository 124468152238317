*{
  ::selection {
    background: #00FA9A;
  }
  ::-moz-selection {
    background: #00FA9A;
  }
}

body{
  font-family: 'Roboto', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;

}

#root{
  overflow-x: hidden;
}

.oneLove{
  color: #cacaca;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 14px;
  margin-right: 30px;
  letter-spacing: .8px;

  @media (max-width: 767px){
    display: none;
  }
}